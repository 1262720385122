import {computed} from 'vue';
import {storeToRefs} from "pinia";
import {useCustomizerStore} from "~/stores/customizer";

export const useCodeTheme = () => {
    const customizerStore = storeToRefs(useCustomizerStore())
    const codeTheme = computed(() => {
        if (customizerStore.actTheme.value === 'DARK_THEME') {
            return 'sunburst'
        } else {
            return 'foundation'; //light,
        }
    });
    return {codeTheme};
}
