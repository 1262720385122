export interface EnvironmentConfiguration {
    auth0Domain: string,
    auth0ClientId: string,
    unleashBaseUrl: string,
    unleashToken: string,
    connectorHost?: string
    connectorPort?: number
    connectorUnsecure?: boolean
    controlTowerHost?: string
    controlTowerPort?: number
    controlTowerUnsecure?: boolean
    sentryEnabled: boolean
}

// Defaults refer to the local and dev environments
const defaults: EnvironmentConfiguration = {
    auth0Domain: 'dev-kuzncjsbid5wh7w6.us.auth0.com',
    auth0ClientId: 'OH9t0U3YaQABA4JeHVxHHz8QtuN5aONN',
    unleashBaseUrl: 'https://unleash.dev.axoniq.net/',
    unleashToken: '*:development.4ed221b3c89613378aa5ba1fa3e784dc90080d5dcda070469d4cb6fc',
    connectorHost: '34.111.212.157',
    connectorPort: 7000,
    connectorUnsecure: false,
    controlTowerHost: '34.110.221.42',
    controlTowerPort: 7001,
    controlTowerUnsecure: false,
    sentryEnabled: true
}


const localOptions: EnvironmentConfiguration = Object.assign({}, defaults, {
    connectorHost: 'localhost',
    connectorUnsecure: true,
    controlTowerHost: 'localhost',
    controlTowerPort: 7001,
    controlTowerUnsecure: true,
    unleashBaseUrl: 'http://localhost:4242/',
    unleashToken: 'default:development.unleash-insecure-frontend-api-token',
    sentryEnabled: false,
});

const prodOptions: EnvironmentConfiguration = Object.assign({}, defaults, {
    auth0Domain: 'axoniq.eu.auth0.com',
    auth0ClientId: '75wlpOLEFWypcS7wufEzSd89KzGjWO1E',
    connectorHost: 'framework.console.axoniq.io',
    controlTowerHost: 'axonserver.console.axoniq.io',
    controlTowerPort: 7001,
    unleashBaseUrl: 'https://unleash.axoniq.net/',
    unleashToken: '*:production.cbc402ce6562f4cf034aae236f527e04200a53e7cb513ce643a21cb4',
})

let devOptions: EnvironmentConfiguration = Object.assign({}, defaults, {
    controlTowerPort: 7000,
});
const environments: { [host: string]: EnvironmentConfiguration } = {
    // Dev:Local
    'localhost:3000': localOptions,
    // Dev:remote
    'console-2.dev.axoniq.net': devOptions,
    'console.dev.axoniq.net': devOptions,
    'console-2.axoniq.io': prodOptions,
    'console.axoniq.io': prodOptions,
}

export const getConfig = () => {
    if (window.location.host.includes("inspector.axoniq.io")) {
        window.location.href = "https://console.axoniq.io"
    }
    let optionalEnvironment = environments[window.location.host];
    if (!optionalEnvironment) {
        console.error(`Environment not found for hostname ${window.location.host}. Defaulting to localhost config!`)
        optionalEnvironment = environments['localhost:3000'];
    }
    return optionalEnvironment
}

export const getProdConfig = () => {
    return prodOptions
}