import {storeToRefs} from "pinia";
import {computed, onUnmounted} from "vue";
import type {HandlerSummary} from "~/types/spaces.types";
import {defineMultiPollingStore} from "~/stores/_archetypes/polling";


export const useHandlerSummary = (workspaceId: string) => {
    const store = handlerSummaryStore()

    const id = store.registerPolling({workspaceId})
    onUnmounted(() => {
        store.deregisterPolling(id)
    })

    const storeRefs = storeToRefs(store)
    const summary = computed((() => {
        return storeRefs.state.value[id]?.item as HandlerSummary | null
    }))
    const loading = computed((() => {
        return storeRefs.state.value[id]?.loading
    }))
    const error = computed((() => {
        return storeRefs.state.value[id]?.error
    }))

    return {loading, summary, error}
}


export const handlerSummaryStore = defineMultiPollingStore<
    { workspaceId: string },
    HandlerSummary
>(
    'handlerSummary',
    'workspace/handler/summary',
    10000
)

