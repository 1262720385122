<template>
  <div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>

    <DebugStoreDialog/>
  </div>
</template>

<script setup lang="ts">
// 1. Set up default denied consent
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer!!.push(arguments);
}
gtag('consent', 'default', {
  'analytics_storage': 'denied',
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'region': ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IS", "IE", "IT", "LV", "LI", "LT", "LU", "MT", "NL", "NO", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "UK", "CH"]
});

// 2. Send consent updates from the cookie banner to google tags

var _hsp = window._hsp = window._hsp || [];
_hsp.push(['addPrivacyConsentListener', function(consent: any) {
  gtag('consent', 'update', {
    'ad_storage': consent.allowed ? 'granted' : 'denied',
    'analytics_storage': consent.allowed ? 'granted' : 'denied',
    'ad_user_data': consent.allowed ? 'granted' : 'denied',
    'ad_personalization': consent.allowed ? 'granted' : 'denied'
  });
}]);
</script>