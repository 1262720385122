import {ref} from "vue";
import {defineStore} from "pinia";

interface UiNotification {
    type: string
    message: string,
    timeout?: number
}

interface UiNotificationWithId extends UiNotification {
    id: number
}

export const useUiNotifications = defineStore('notifications', () => {
    const notifications = reactive<UiNotificationWithId[]>([])
    const idCounter = ref(0)

    const showUiNotification = (notification: UiNotification) => {
        const nextId = idCounter.value++
        notifications.push({...notification, id: nextId})
        setTimeout(() => {
            dismissUiNotification(nextId)
        }, notification.timeout || 5000)
    }

    const dismissUiNotification = (id: number) => {
        notifications.splice(notifications.findIndex(n => n.id === id), 1)
    }


    return {
        notifications,
        showUiNotification,
        dismissUiNotification,
    }
});
