import {computed, ref, watchEffect} from "vue";
import {acceptHMRUpdate, defineStore} from "pinia";
import {useAuth0} from "@auth0/auth0-vue";
import * as Sentry from "@sentry/vue";
import {auth0Plugin} from "~/utils/auth0";
import type {UserResponse} from "~/types/user.types";
import {getUser} from "~/calls/user";

/**
 * During boot of the application there are several phases.
 *
 * 1. Is someone logged in? (auth0.isAuthenticated) -> If so, fetch user information from backend
 * 2. Did the user register? (UserResponse.registered) -> If not, show registration page
 * 3. Did the user accept the terms? (UserResponse.termsAccepted) -> If not, show terms page
 * 4. Login complete!
 */

export const useAuthentication = defineStore('authentication', () => {
    const auth0 = useAuth0()
    const user = ref<UserResponse | null>(null)
    const userLoading = ref(false)
    const userLoadError = ref<string | null>(null)

    const userTutorialCompleted = computed(() => user.value?.tutorialFinished === true)
    const showLogin = computed(() => !loading.value && !auth0.isAuthenticated.value)
    const showRegistration = computed(() => user.value && (user.value?.registered !== true || user.value?.termsAccepted !== true))
    const showTermsScreen = computed(() => !loading.value && user.value && user.value?.termsAccepted !== true)
    const showShouldConfirmEmailScreen = computed(() => !loading.value && user.value && user.value?.verifiedEmail !== true)

    const email = computed(() => user.value?.email)
    const name = computed(() => {
        if (user.value) {
            return (user.value!!.firstName || '') + " " + (user.value!!.lastName || '')
        }
        return ''
    })
    const pictureUrl = computed(() => user.value?.pictureUrl)
    const root = computed(() => user.value?.root === true)

    const logout = () => {
        const host = window.location.host
        const protocol = window.location.protocol
        auth0.logout({logoutParams: {returnTo: `${protocol}//${host}`}})
    }

    const loading = computed(() => {
        return auth0.isLoading.value
    })

    const authenticated = computed(() => {
        return !auth0.isLoading.value && auth0.isAuthenticated.value && !userLoading.value
    })

    const authError = computed(() => {
        const auth0error = auth0.error.value;
        if (auth0error === 'login_required') {
            return null
        }
        const localError = userLoadError.value;
        return auth0error ? auth0error?.error_description + ' (' + auth0error.error + ')' : localError;
    })

    const authenticatedAndAccepted = computed(() => {
        return authenticated.value && !showTermsScreen.value && !showRegistration.value && !showShouldConfirmEmailScreen.value
    })

    const login = async (provider: string) => {
        await auth0.loginWithRedirect({
            authorizationParams: {connection: provider},
            appState: {target: window.location.pathname}
        })
    }


    const signup = async () => {
        await auth0.loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup',
            },
            appState: {target: window.location.pathname}
        })
    }


    const refreshUser = async () => {
        user.value = await getUser()
    }

    watchEffect(() => {
        const auth0error = auth0.error.value;

        if (auth0error && auth0error.error === "login_required") {
            // Login expired and we need to re-redirect
            // Previous login method should be in local storage
            const previousProvider = localStorage.getItem("previous_auth_provider")
            if (previousProvider && previousProvider.length > 0) {
                login(previousProvider)
            } else {
                // reload page, so clear the auth0 error
                window.location.reload()
            }
            return
        }
    })

    watchEffect(() => {
        if (auth0.user.value) {
            Sentry.setUser({
                email: auth0.user.value?.email,
                id: auth0.user.value?.sub,
            })
            const method = auth0.user.value!!.sub.split('|')[0]
            localStorage.setItem("previous_auth_provider", method)
        }
    })

    if (window.location.search.includes('state=')) {
        auth0Plugin.handleRedirectCallback().then(({appState}: { appState: { target: any } }) => {
            if (appState?.target) {
                const router = useRouter()
                router.push(appState.target)
            }
        }).catch((e) => {
            console.error("Error while handling redirect callback", e)
        })
    }

    return {
        authError,
        authenticated,
        authenticatedAndAccepted,
        refreshUser,
        showLogin,
        showRegistration,
        showTermsScreen,
        showShouldConfirmEmailScreen,
        email,
        name,
        loading,
        logout,
        login,
        signup,
        pictureUrl,
        userLoading,
        userLoadError,
        root: root,
        userTutorialCompleted,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthentication, import.meta.hot))
}