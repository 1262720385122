import {onMounted, onUnmounted, ref} from "vue";

export const useInterval = (action: () => void, interval: number, immediate: boolean = true) => {
    let scheduledInterval: any;
    if(immediate) {
        action()
    }
    onMounted(() => {
        scheduledInterval = setInterval(() => {
            action()
        }, interval)
    })
    onUnmounted(() => clearInterval(scheduledInterval))
}

export const useReactiveCurrentDate = (updateInterval: number = 500) => {
    const currentDate = ref<Date>(new Date())
    useInterval(() => {
        currentDate.value = new Date()
    }, updateInterval)
    return currentDate
}