<script setup lang="ts">
import {useAuthentication} from "~/stores/authentication";
import {storeToRefs} from "pinia";
import {CrownIcon} from "vue-tabler-icons";
import {useCustomizerStore} from "~/stores/customizer";

const {pictureUrl, name, email, root} = storeToRefs(useAuthentication())
const customizer = useCustomizerStore();


const theme = ref(localStorage.getItem('lighttheme') !== 'false');

const changeTheme = () => {
  theme.value = !theme.value;
  const themeSelected = theme.value ? 'LIGHT_THEME' : 'DARK_THEME';
  customizer.SET_THEME(themeSelected);
  localStorage.setItem('lighttheme', theme.value + '');
};
</script>

<template>
  <div>
    <v-menu>
      <template #activator="{ props }">
        <v-sheet icon v-bind="props" class="d-flex gap-3 align-center px-4 py-1 cursor-pointer">
          <v-badge :icon="CrownIcon" color="#FFBB48" v-if="root">
            <v-avatar :image="pictureUrl"></v-avatar>
          </v-badge>
          <v-avatar :image="pictureUrl" v-else></v-avatar>
          <div>
            <div>{{name}}</div>
            <div>{{email}}</div>
          </div>
          <div>
            <ChevronDownIcon class="mt-3"/>
          </div>
        </v-sheet>
      </template>
      <v-list>
        <v-list-item-title>
          <v-btn href="https://library.axoniq.io/axoniq-console-getting-started/main/index.html" variant="text" color="primary" style="width: 100%" target="_blank">
            <BookIcon stroke-width="1.5" class="mr-1" size="22" />Browse documentation
          </v-btn>
        </v-list-item-title>
        <v-list-item-title>
          <v-btn @click.prevent="changeTheme" variant="text" color="primary" style="width: 100%">
            <BulbFilledIcon stroke-width="1.5" class="mr-1" size="22" />Switch to {{theme ? 'dark' : 'light'}} theme
          </v-btn>

        </v-list-item-title>
        <v-list-item-title v-if="root">
          <v-btn variant="text" to="/root" color="primary" style="width: 100%">
            <ExclamationCircleIcon stroke-width="1.5" class="mr-1" size="22" /> Super Administration
          </v-btn>
        </v-list-item-title>
        <v-list-item-title>
          <NavbarLogout/>
        </v-list-item-title>
      </v-list>
    </v-menu>
  </div>
</template>
