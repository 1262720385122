import {defineStore, storeToRefs} from "pinia";
import {useAuthentication} from "~/stores/authentication";
import {useConnectionStore} from "~/stores/connection";
import {useSpacesStore} from "~/stores/spaces";

export enum ApplicationPhase {
    INITIAL,
    LOADING_AUTH_PROVIDER,
    ESTABLISHING_CONNECTION,
    LOADING_USER,
    LOADING_WORKSPACES,
    READY,
    CONNECTION_LOST,
}

export const useApplicationState = defineStore('application-state', () => {
    const {
        loading: authProviderLoading, authError, authenticated
    } = storeToRefs(useAuthentication())
    const {loading: workspacesLoading, error: workspacesError} = storeToRefs(useSpacesStore())

    const {refreshUser} = useAuthentication()
    const {connect, disconnect} = useConnectionStore()
    const {isConnected} = storeToRefs(useConnectionStore())
    const phase = ref<ApplicationPhase>(ApplicationPhase.INITIAL)
    const error = ref<string | null>(null)
    const isLoading = ref(true)
    const isReconnecting = computed(() => phase.value === ApplicationPhase.CONNECTION_LOST)


    const loadPercentage = computed(() => {
        if (phase.value === ApplicationPhase.INITIAL) {
            return 0
        }
        if (phase.value === ApplicationPhase.READY) {
            return 100
        }
        return Math.floor((phase.value / (ApplicationPhase.READY + 1)) * 100)
    })

    const setPhase = (newPhase: ApplicationPhase) => {
        if (phase.value !== newPhase) {
            setTimeout(() => {
                error.value = null
                console.log(`[BOOT] Moved phase ${ApplicationPhase[phase.value]} to ${ApplicationPhase[newPhase]}`)
                phase.value = newPhase
            }, 100)
        }
    }
    const nextPhase = () => {
        setPhase(phase.value + 1)
    }
    setPhase(ApplicationPhase.INITIAL)

    const setPhaseError = (newPhase: ApplicationPhase, e: string) => {
        console.log(`[BOOT] Moved to phase ${ApplicationPhase[newPhase]} with error: ${e}`)
        phase.value = newPhase
        error.value = e
    }

    /**
     * When authentication is loading, we change phase. If it's no longer loading, and the user is authenticated,
     * we move to the next phase.
     */
    watch([authProviderLoading, authenticated, authError], () => {
        // Note that we don't have to initialize auth0 to check if it's logged in. This is done automatically
        if (authProviderLoading.value) {
            setPhase(ApplicationPhase.LOADING_AUTH_PROVIDER)
            return;
        }

        if (authenticated.value) {
            setPhase(ApplicationPhase.ESTABLISHING_CONNECTION)
        } else if (authError.value) {
            setPhaseError(ApplicationPhase.LOADING_AUTH_PROVIDER, authError.value)
        } else {
            // Not authenticated, not error, is logged out -> Keep on INITIAL
            setPhase(ApplicationPhase.INITIAL)
        }
    }, {immediate: true})

    watch(phase, phase => {
        if (phase === ApplicationPhase.ESTABLISHING_CONNECTION) {
            establishConnection()
        }

        if (phase === ApplicationPhase.LOADING_USER) {
            loadUser()
        }

        if (phase === ApplicationPhase.LOADING_WORKSPACES) {
            loadWorkspaces()
        }

        if(phase === ApplicationPhase.CONNECTION_LOST) {
            isLoading.value = true
            establishConnection()
        }

        if (phase === ApplicationPhase.READY) {
            setTimeout(() => {
                isLoading.value = false
            }, 250)
        }
    }, {immediate: true})

    function establishConnection() {
        connect().then(() => {
            setPhase(ApplicationPhase.LOADING_USER)
        }).catch(e => {
            setPhaseError(phase.value, e.message)
            setTimeout(() => {
                establishConnection()
            })
        })
    }

    function loadUser() {
        refreshUser().then(() => {
            setPhase(ApplicationPhase.LOADING_WORKSPACES)
        }).catch(e => {
            setPhaseError(ApplicationPhase.LOADING_USER, e)
            setTimeout(() => {
                loadUser()
            }, 1000)
        })
    }

    function loadWorkspaces() {
        return new Promise((resolve, reject) => {
            if (!workspacesLoading.value) {
                resolve()
            } else {
                if(workspacesError.value) {
                    setPhaseError(ApplicationPhase.LOADING_WORKSPACES, workspacesError.value!!)
                }
                // Still loading. Wait a bit.
                setTimeout(() => {
                    loadWorkspaces().then(resolve).catch(reject)
                }, 100)
            }
        }).then(() => {
            nextPhase()
        })
    }

    let recheckTimeout = null
    watch(isConnected, (connected) => {
        if(connected && recheckTimeout !== null) {
            clearTimeout(recheckTimeout)
            recheckTimeout = null
            return
        }

        if(phase.value !== ApplicationPhase.READY || recheckTimeout !== null) {
            return
        }
        recheckTimeout = setTimeout(() => {
            // Give it a bit of time to reconnect automatically
            if (!isConnected.value) {
                setPhase(ApplicationPhase.CONNECTION_LOST)
            }
            recheckTimeout = null
        }, 10000)
    })

    return {
        phase: computed(() => ApplicationPhase[phase.value]),
        error,
        loadPercentage,
        isLoading,
        isReconnecting,
    }
})