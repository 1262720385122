import type {Environment} from "~/types/spaces.types";

export const toPlanType = (environment: Environment) => {
    if(!environment.clusterActivated) {
        return null
    }
    const p = environment.cluster?.features?.name
    if(p?.startsWith("STARTER")) {
        return "STARTER"
    }
    if(p?.startsWith("PRO")) {
        return "PRO"
    }
    if(p?.startsWith("DEVELOPER")) {
        return "DEVELOPER"
    }
    if(p?.startsWith("Enterprise")) {
        return "ENTERPRISE"
    }
    return null
}