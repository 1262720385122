import {computed} from "vue";
import {useEnvironment, useWorkspace} from "~/stores/spaces";
import type {Cluster, ClusterNodeStoreRepresentation, NodeStatus} from "~/types/cluster.types";
import {useStream} from "~/stores/_archetypes/rsocket";
import {useReactiveCurrentDate} from "~/utils/useInterval";

export const useCluster = () => {
    const {environmentId, workspaceId, environment} = useEnvironment()
    return streamCluster(workspaceId.value, environmentId.value, environment.value?.cluster?.clusterId ?? '')

}


export const streamClusterForEnvironment = (workspaceId: string, environmentId: string) => {
    return streamCluster(workspaceId, environmentId, useWorkspace().workspace.value?.environments.find(e => e.identifier === environmentId)?.cluster?.clusterId ?? '')
}

interface ClusterCombinedInitial {
    cluster: Cluster,
    health:
        {
            clusterId: string,
            statuses: {
                [nodeName: string]: {
                    status: NodeStatus,
                    reportTime: string
                }
            }
        }
}

interface ClusterCombinedUpdate {
    clusterUpdate: {
        clusterItem: Cluster,
        eventType: 'ADDED' | 'UPDATED' | 'REMOVED'
    },
    healthUpdate: {
        clusterId: string,
        healthInformation: {
            status: NodeStatus,
            reportTime: string
        },
        nodeName: string,
        updateType: 'REFRESH' | 'DISCONNECTED' | 'REMOVED'
    },
}

interface ClusterCombinedStoreState {
    cluster: Cluster,
    health: {
        [nodeName: string]: {
            status: NodeStatus,
            reportTime: string
        }
    }
}


export const streamCluster = (workspaceId: string, environmentId: string, clusterId: string) => {
    const dateReactive = useReactiveCurrentDate(1000)

    const streamState = useStream<
        ClusterCombinedInitial,
        ClusterCombinedUpdate,
        ClusterCombinedStoreState>(
        'cluster/stream',
        {workspaceId, environmentId, clusterId},
        data => {
            return {
                cluster: data.cluster,
                health: data.health.statuses
            }
        },
        (update: ClusterCombinedUpdate, state: ClusterCombinedStoreState): ClusterCombinedStoreState => {
            if (update.clusterUpdate) {
                if (update.clusterUpdate.eventType !== 'REMOVED') {
                    return {...state, cluster: update.clusterUpdate.clusterItem}
                }
            }
            if (update.healthUpdate) {
                if (update.healthUpdate.updateType === 'REFRESH') {
                    return {
                        ...state,
                        health: {
                            ...state.health,
                            [update.healthUpdate.nodeName]: {
                                status: update.healthUpdate.healthInformation.status,
                                reportTime: update.healthUpdate.healthInformation.reportTime
                            }
                        }
                    }
                } else {
                    const health = {...state.health}
                    delete health[update.healthUpdate.nodeName]
                    return {
                        ...state,
                        health
                    }
                }
            }
        }
    )
    const cluster = computed((() => {
        return streamState.item.value?.cluster
    }))
    const healthStatus = computed((() => {
        return streamState.item?.value?.health
    }))
    const nodes = computed<ClusterNodeStoreRepresentation[]>(() => {
        const nodesRegistered = cluster.value?.nodes?.map(n => n.nodeName)?.sort((a, b) => a.localeCompare(b)) || []
        const allowedNodeCount = cluster.value?.plan?.serverNodes || 1
        const health = healthStatus.value
        const date = dateReactive.value

        const indices = Array.from({ length: allowedNodeCount < 3 ? 3 : allowedNodeCount }, (_, i) => i + 1);

        return indices.map(index => {
            if (index > allowedNodeCount) {
                return {
                    index,
                    name: null,
                    included: false,
                    unknown: false,
                    setup: false,
                    online: false,
                    status: null,
                    reportTime: null,
                    node: null,
                }
            }
            if (!cluster.value) {
                return {
                    index,
                    name: null,
                    unknown: true,
                    included: true,
                    setup: false,
                    online: false,
                    status: null,
                    reportTime: null,
                    node: null,
                }
            }
            if (index > nodesRegistered.length) {
                return {
                    index,
                    name: null,
                    unknown: false,
                    included: true,
                    setup: false,
                    online: false,
                    status: null,
                    reportTime: null,
                    node: null,
                }
            }
            const nodeName = nodesRegistered[index - 1]
            const nodeHealth = health?.[nodeName]
            const online = nodeHealth && ((date.getTime()) - (new Date(nodeHealth.reportTime * 1000)).getTime() < 60000);
            return {
                index,
                name: nodeName,
                included: true,
                setup: true,
                unknown: false,
                online: online,
                status: online ? nodeHealth?.status!! : null,
                reportTime: nodeHealth?.reportTime ?? null,
                node: cluster.value?.nodes?.find(n => n.nodeName === nodeName) ?? null,
            }
        })
    })

    return {loading: streamState.loading, cluster, healthStatus, error: streamState.error, nodes}
}