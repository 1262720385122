import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {useConnectionStore} from "~/stores/connection";

export function createPollingStatisticStore<T, R>(name: string, url: string, extractor: (body: R) => T[]) {
    return defineStore(name, ()=> {
        const {query} = useConnectionStore()
        const values = ref<T[]>()
        const loading = ref(true)
        const errorCount = ref(0)
        const error = ref(false)
        const lastDate = ref<Date | null>(null)
        const references = ref(0)
        let interval: any;

        async function fetchData(workspaceId: string, environmentId: string) {
            try {
                const data = await query(url, {
                    workspaceId, environmentId, lookback: 10
                }, true) as R

                loading.value = false
                values.value = extractor(data)
                lastDate.value = new Date()
                errorCount.value = 0
            } catch (e) {
                errorCount.value += 1
                if (errorCount.value > 5) {
                    error.value = true
                    values.value = []
                }
            }
        }

        const startPolling = (workspaceId: string, environmentId: string) => {
            references.value += 1
            if(interval) {
                return
            }
            loading.value = true
            fetchData(workspaceId, environmentId)
            interval = setInterval(async () => {
                await fetchData(workspaceId, environmentId);
            }, 5000)
        }

        const stopPolling = () => {
            references.value -= 1
            if(references.value === 0) {
                if (interval) clearInterval(interval)
                interval = null;
                values.value = []
            }
        }

        const nonNullValue = computed(() => values.value || [])

        return {loading, values: nonNullValue, startPolling, stopPolling, lastDate, error}
    })
}
