import {acceptHMRUpdate} from 'pinia'
import {createPollingStatisticStore} from "~/stores/_archetypes/statistics";
import type {AggregateInformationDto, AggregateStatisticOverviewResponse} from "~/types/statistics.aggregate.types";


export const useAggregateStore = createPollingStatisticStore<AggregateInformationDto, AggregateStatisticOverviewResponse>(
    'aggregates',
    'stats/aggregates/overview',
    body => body.aggregates)

const useAggregateStatistics = (workspaceId: string, environmentId: string) => {
    const {startPolling, stopPolling, values} = useAggregateStore()
    onMounted(() => startPolling(workspaceId, environmentId))
    onUnmounted(() => stopPolling())
    return storeToRefs(useAggregateStore())
}

export const useAggregateStatisticsForCurrent = () => {
    const {workspaceId, environmentId} = useEnvironment()
    return useAggregateStatistics(workspaceId.value, environmentId.value)
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAggregateStore, import.meta.hot))
}
