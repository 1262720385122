<script setup lang="ts">
import logoLight from '/assets/logo/discuss-light.svg';
import logoDark from '/assets/logo/discuss-dark.svg';

const props = defineProps<{size?: number, greyScale?: boolean}>();
const style = props.greyScale ? "filter: grayscale(1)" : ""
</script>
<template>
  <ThemedComponent>
    <template v-slot:dark>
      <v-img :src="logoLight" alt="AxonIQ Discuss Logo" :height="size || 50" :width="size || 50" :style="style" eager/>
    </template>
    <template v-slot:light>
      <v-img :src="logoDark" alt="AxonIQ Discuss Logo" :height="size || 50" :width="size || 50" :style="style" eager/>
    </template>
  </ThemedComponent>
</template>
