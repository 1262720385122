import {defineStore} from "pinia";
import config from '@/config'


export const useCustomizerStore = defineStore({
  id: "customizer",
  state: () => ({
    actTheme: config.actTheme,
  }),

  getters: {},
  actions: {
    SET_THEME(payload: any) {
      this.actTheme = payload;
    },
  },
});
