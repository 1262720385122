import {createPollingStatisticStore} from "~/stores/_archetypes/statistics";
import {acceptHMRUpdate} from "pinia";
import type {HandlerInformationDto, HandlerStatisticResponse} from "~/types/statistics.handlers.types";
import type {Ref} from "vue";

export const useHandlerStore = createPollingStatisticStore<HandlerInformationDto, HandlerStatisticResponse>(
    'handlers',
    'stats/handlers/overview',
    body => body.handlers)

export const useHandlerStatistics = (workspaceId: string, environmentId: string): {
    values: Ref<HandlerInformationDto[]>
    loading: Ref<boolean>
    error: Ref<boolean>
    lastDate: Ref<Date | null>
} => {
    const {startPolling, stopPolling, values} = useHandlerStore()
    onMounted(() => startPolling(workspaceId, environmentId))
    onUnmounted(() => stopPolling())
    return storeToRefs(useHandlerStore())
}

export const useHandlerStatisticsForCurrent = (): {
    values: Ref<HandlerInformationDto[]>;
    loading: Ref<boolean>;
    error: Ref<boolean>;
    lastDate: Ref<Date | null>
} => {
    const {workspaceId, environmentId} = useEnvironment()
    return useHandlerStatistics(workspaceId.value, environmentId.value)
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHandlerStore, import.meta.hot))
}
