import {useConnectionStore} from "~/stores/connection";
import type {UserResponse} from "~/types/user.types";

export const getUser = () => {
    return useConnectionStore().query<UserResponse>('user/get', null, true)
}

export const registerOrUpdateUser = (firstName: string, lastName: string, organization: string | null, role: string | null, termsAccepted: boolean) => {
    return useConnectionStore().execute('user/register-or-update', {
        firstName, lastName, organization, role, termsAccepted
    })
}

export const acceptTermsForUser = () => {
    return useConnectionStore().execute('user/accept-terms')
}

export const finishTutorialForUser = () => {
    return useConnectionStore().execute('user/finish-tutorial')
}