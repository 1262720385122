import {plugin as unleashPlugin} from '@unleash/proxy-client-vue'

export default defineNuxtPlugin((nuxtApp) => {
    const appConfig = getConfig()
    const config = {
        url: appConfig.unleashBaseUrl + '/api/frontend',
        clientKey: appConfig.unleashToken,
        refreshInterval: 15,
        appName: 'Console'
    }

    nuxtApp.vueApp.use(unleashPlugin, {config})
})