import {getConfig} from "~/utils/configuration";
import {createAuth0} from "@auth0/auth0-vue";

const environmentConfig = getConfig()

export const auth0Plugin = createAuth0({
    domain: environmentConfig.auth0Domain,
    clientId: environmentConfig.auth0ClientId,
    legacySameSiteCookie: false,
    cacheLocation: 'localstorage',
    authorizationParams: {
        redirect_uri:  window.location.origin,
        audience: 'https://console.axoniq.io',
    }
}, {skipRedirectCallback: true});