import type {ThemeTypes} from '@/types/themeTypes/ThemeType';


const DARK_THEME: ThemeTypes = {
    name: 'DARK_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#FE5E00', //fox
        secondary: '#00B7BF', //Peacock 1374CF
        info: '#00B7BF', //flamingo
        success: '#47BF4A', //frog
        accent: '#FA896B',
        warning: '#E6D352', //ducling
        error: '#D33333', //robin
        lightprimary: '#FBCAAD', //pale fox 
        lightsecondary: '#B6F5ED',//pale peacocks
        lightsuccess: '#BAFFBC', //palefrog
        lighterror: '#f7cece', //palerobin
        lightwarning: '#F7EFBA',//paleduckling
        textPrimary: '#EAEFF4',
        textSecondary: '#7C8FAC',
        borderColor: '#333F55',
        inputBorder: '#465670',
        containerBg: '#171c23',
        background: '#171c23',
        hoverColor: '#333f55',
        surface: '#171c23',
        'on-surface-variant': '#171c23',
        grey100: '#333F55',
        grey200: '#465670'
    }
};

export {DARK_THEME};
