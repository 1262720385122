import {computed, Ref, ref} from 'vue'
import {acceptHMRUpdate, defineStore, storeToRefs} from 'pinia'
import type {Workspace} from "~/types/spaces.types";
import {useAuthentication} from "~/stores/authentication";
import {toPlanType} from "~/utils/subscription";
import {capitalize} from "~/utils/strings";
import {useConnectionStore} from "~/stores/connection";

export const useSpacesStore = defineStore('spaces', (): {
    workspacesWithoutDemo: Ref<Workspace[]>;
    workspaces: Ref<any[]>;
    loading: Ref<boolean>;
    error: Ref<string | null>
} => {
    const workspaces = ref<Workspace[]>([])
    const {subscribe} = useConnectionStore()
    const loading = ref(true)
    const error = ref<string | null>(null)


    function addSpace(workspace: Workspace) {
        const index = workspaces.value.findIndex((value: Workspace) => value.identifier == workspace.identifier)
        if (index > -1) {
            workspaces.value[index] = workspace
        } else {
            workspaces.value.push(workspace)
        }
    }

    subscribe<Workspace[], { updated: Workspace, removed: Workspace }>('workspaces/stream', null, {
        onConnected: (initialData) => {
            workspaces.value = initialData
            loading.value = false
        },
        onUpdate: (data) => {
            if (data.updated) {
                addSpace(data.updated)
            } else if (data.removed) {
                workspaces.value.splice(workspaces.value.findIndex(s => s.identifier === data.removed.identifier), 1)
            }
        },
        onError: (e) => {
            error.value = e.message
            loading.value = true
        }
    })

    const sortedWorkspaces = computed(() => {
        return workspaces.value?.sort((a, b) => a.name.localeCompare(b.name)) || []
    });
    const workspacesWithoutDemo = computed(() => {
        return workspaces.value.filter(workspace => workspace.identifier !== 'demo')
    })
    return {
        workspaces: sortedWorkspaces, workspacesWithoutDemo, loading, error
    }
})

export function useWorkspace(): {
    workspace: Ref<Workspace>
    found: Ref<boolean>
    isEnterprise: Ref<boolean>
    hasPremium: Ref<boolean>
    workspaceId: Ref<string>
} {
    const router = useRouter();
    const {workspaces} = storeToRefs(useSpacesStore());
    const workspaceId = computed(() => router.currentRoute.value.params.workspaceId as string)
    const found = computed(() => !!workspace.value)

    const workspace = computed(() => {
        return workspaces.value.find(space => space.identifier === workspaceId.value)!!;
    })

    const hasPremium = computed(() => {
        return workspace.value?.featureSet.subscriptionType !== 'NONE'
    })
    const isEnterprise = computed(() => {
        return workspace.value.featureSet.subscriptionType === 'ENTERPRISE'
    })

    return {found, workspace, workspaceId, hasPremium, isEnterprise}
}

export const useEnvironment = () => {
    const {workspace, workspaceId} = useWorkspace()
    const router = useRouter();
    const environmentId = computed(() => router.currentRoute.value.params.environmentId as string)
    const environment = computed(() => {
        return workspace.value?.environments?.find(env => env.identifier === environmentId.value)!!
    })
    const cluster = computed(() => environment.value.cluster)
    const clusterId = computed(() => cluster.value?.clusterId || 'UNKNOWN')
    const baseUrl = computed(() => `/workspace/${workspaceId.value}/env/${environmentId.value}`)

    return {environment, workspace, workspaceId, environmentId, cluster, clusterId, baseUrl}
}

export const useEnvironmentCluster = () => {
    const {environment} = useEnvironment()
    const {cluster, nodes} = useCluster()
    const {subscriptionInfo} = useSubscription()
    const allowedNodeCount = computed(() => {
        return environment.value.cluster?.features.nodeCount ?? 1
    })
    const allowedConnections = computed(() => {
        return environment.value.cluster?.features.applicationConnectionCount ?? 2147483647
    })

    const hasConnectionRestrictions = computed(() => {
        const connectionLimit = allowedConnections.value;
        return connectionLimit > 0 && connectionLimit < 2147483647
    });

    const plan = computed(() => {
        return toPlanType(environment.value)
    })
    const planRaw = computed(() => {
        return environment.value.cluster?.features?.name ?? 'DEVELOPER'
    })
    const planId = computed(() => {
        return environment.value.cluster?.featuresId
    })
    const planFormatted = computed(() => {
        return capitalize(plan.value) + ' Plan'
    });

    const clusterActivated = computed(() => {
        const hasActiveNode = cluster.value?.nodes?.length > 0
        return hasActiveNode || environment.value.clusterActivated
    })
    const environmentCluster = computed(() => {
        return environment.value.cluster
    })
    const clusterId = computed(() => {
        return environment.value.cluster?.clusterId
    })
    const currentOrPendingPlan = computed(() => {
        const value = subscriptionInfo.value
        if (value?.nextCyclePlans[planId.value]) {
            return value.nextCyclePlans[planId.value].planType
        }
        return plan.value
    })


    return {
        currentOrPendingPlan,
        clusterId,
        allowedNodeCount,
        planId,
        plan,
        planRaw,
        planFormatted,
        clusterActivated,
        hasConnectionRestrictions,
        allowedConnections,
        environmentCluster
    }
}


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSpacesStore, import.meta.hot))
}

export function useCurrentWorkspaceUser(): {
    userWorkspaceRole: Ref<string>,
    workspaceOwnerEmail: Ref<string>,
    isViewer: Ref<boolean>,
    isAdmin: Ref<boolean>,
    isOwner: Ref<boolean>,
} {
    const {workspace} = useWorkspace()
    const userRole = computed(() => workspace.value?.users?.find(u => u.email === useAuthentication().email)?.role || 'VIEWER');

    const workspaceOwnerEmail = computed(() => {
        return workspace.value.users.find(user => user.role === 'OWNER')?.email || ''
    })

    return {
        userWorkspaceRole: userRole,
        isViewer: computed(() => userRole.value === 'VIEWER'),
        isAdmin: computed(() => userRole.value === 'OWNER' || userRole.value === 'ADMIN'),
        isOwner: computed(() => userRole.value === 'OWNER'),
        workspaceOwnerEmail,
    }
}