import {createVuetify} from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import * as directives from "vuetify/directives";

import {PerfectScrollbarPlugin} from 'vue3-perfect-scrollbar';
import VueTablerIcons from "vue-tabler-icons";

import "@/assets/scss/style.scss";
import 'vue3-perfect-scrollbar/style.css';

import {LIGHT_THEME,} from "@/theme/LightTheme";
import {DARK_THEME,} from "@/theme/DarkTheme";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components: {
    },
    directives,
    theme: {
      defaultTheme: "LIGHT_THEME",
      themes: {
        LIGHT_THEME,
        DARK_THEME,
      },
    },
    defaults: {
      VCard: {
        rounded: "md",
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VTextarea: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VSelect: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VListItem: {
        minHeight: "45px",
      },
      VTooltip: {
        location: "top",
      },
    },
  });


  nuxtApp.vueApp.use(vuetify);
  nuxtApp.vueApp.use(PerfectScrollbarPlugin);
  nuxtApp.vueApp.use(VueTablerIcons);
});
