import {computed, onUnmounted, Ref} from "vue";
import type {Subscription, SubscriptionPlan, TrialEligibilityInformation} from "~/types/subscription.types";
import {useStream} from "~/stores/_archetypes/rsocket";
import {defineMultiPollingStore} from "~/stores/_archetypes/polling";


export const useSubscription = () => {
    const router = useRouter()
    const workspaceId = router.currentRoute.value.params.workspaceId as string


    const streamState = useStream<Subscription, Subscription, Subscription>(
        'subscription/stream',
        {workspaceId},
        data => data,
        (data, state) => data
    );


    const plansWithId: Ref<(SubscriptionPlan & {planId: string})[]> = computed(() => {
        return Object.keys(streamState.item.value?.plans || {}).map(planId => {
            return {
                planId,
                ...streamState.item.value?.plans[planId]!!
            }
        })
    })

    const plansPendingDowngrade = computed(() => {
        return plansWithId.value.filter(plan => plan.scheduledStatus === 'SCHEDULED_DOWNGRADE')
    })
    const plansPendingCancellation = computed(() => {
        return plansWithId.value.filter(plan => plan.scheduledStatus === 'SCHEDULED_CANCELLATION')
    })

    const plansPendingAnyOperation = computed(() => {
        return plansWithId.value.filter(plan => plan.scheduledStatus !== 'NO_SCHEDULED_OPERATION')
    })

    const planForFramework = computed(() => {
        return plansWithId.value.find(plan => plan.planType === 'TEAM')
    })

    const isTrialActive = computed(() => {
        return streamState.item?.value?.trialActive === true;
    });

    return {
        isTrialActive,
        subscriptionInfo: streamState.item as Ref<Subscription>,
        loading: streamState.loading,
        error: streamState.error,
        plansPendingDowngrade,
        plansPendingCancellation,
        plansPendingAnyOperation,
        plansWithId,
        planForFramework,
    }
}

function getTrialEligibility(workspaceId: string | null) {
    const store = trialEligibilityStore()

    const id = store.registerPolling({workspaceId: workspaceId})
    onUnmounted(() => {
        store.deregisterPolling(id)
    })

    const eligible = computed(() => {
        return true
    })
    const notEligibleReason = computed(() => {
        return store.state[id]?.item?.reason
    })

    return {eligible, notEligibleReason}
}

export const useTrialEligibilityForWorkspace = () => {
    const {workspaceId} = useWorkspace()
    return getTrialEligibility(workspaceId.value);
}

export const useTrialEligibilityForUser = () => {
    return getTrialEligibility(null);
}

const trialEligibilityStore = defineMultiPollingStore<{
    workspaceId: string | null
}, TrialEligibilityInformation>('trial-eligibility', 'subscription/trial-eligibility', 1000 * 60)