import {defineStore} from "pinia";
import {useConnectionStore} from "~/stores/connection";
import {onUnmounted, Ref} from "vue";
import type {Namespace} from "~/types/spaces.types";

interface RSocketStreamStoreState<T> {
    id: string
    url: string,
    payload: any | null,

    references: number,
    loading: Ref<boolean>,
    error: Ref<boolean>,
    item: Ref<T | null>,

    stopHandler: () => void,
    lastCloseTime?: number,
}

export const streamStateStore = defineStore("streaming", () => {
    const {subscribe, cancel} = useConnectionStore()
    const streams = reactive<{ [id: string]: RSocketStreamStoreState<any> }>({})

    const closeStream = (id: string) => {
        if (streams[id]) {
            streams[id].references--
            streams[id].lastCloseTime = Date.now()
            if (streams[id].references <= 0) {
                // Close stream after 5 seconds if still has no references by that time
                // This is to prevent excessive open/close load on the server when there's no need to, as well
                // as delays and load on the frontend.
                setTimeout(() => {
                    let stream = streams[id];
                    if (!stream || stream.references > 0) return
                    // If the stream was closed and re-opened in the meantime, don't close it
                    if (Date.now() - stream.lastCloseTime < 5000) return
                    streams[id].stopHandler()
                    delete streams[id]
                }, 5000)
            }
        }
    }

    const openStream = <I, U, R>(url: string,
                                 payload: any = null,
                                 initialMapper: (data: I) => R,
                                 updateMapper: (update: U, current: R) => R,
    ) => {
        const openStreamId = Object.keys(streams)
            .find(id => {
                return streams[id].url === url && JSON.stringify(streams[id].payload) === JSON.stringify(payload);
            })

        if (openStreamId) {
            streams[openStreamId].references++
            return {
                id: openStreamId,
                item: computed(() => streams[openStreamId].item),
                loading: computed(() => streams[openStreamId].loading),
                error: computed(() => streams[openStreamId].error),
            }
        }

        const id: string = subscribe(url, payload, {
            onConnected: (initialData) => {
                console.log(`[RSOC][${id}] Connected stream with url ${url} and payload ${JSON.stringify(payload)}`)
                streams[id].item = initialMapper(initialData)
                streams[id].loading = false
                streams[id].error = false
            },
            onUpdate: (data) => {
                streams[id].item = updateMapper(data, streams[id].item)
                streams[id].loading = false
                streams[id].error = false
            },
            onError: (error) => {
                console.log(`[RSOC][${id}] Error in stream: ${error}`)
                streams[id].error = true
                streams[id].loading = true
            },
            onDisconnected: () => {
                console.log(`[RSOC][${id}] Disconnected stream`)
                streams[id].item = null
                streams[id].loading = true
            }
        })
        streams[id] = {
            id,
            url,
            payload,
            references: 1,
            loading: true,
            error: false,
            stopHandler: () => {
                cancel(id)
                delete streams[id]
            },
            item: null,
        }
        return {
            id,
            item: computed(() => streams[id].item),
            loading: computed(() => streams[id].loading),
            error: computed(() => streams[id].error),
        }
    }

    return {
        streams,
        openStream,
        closeStream
    }
})

export const useStream = <I, U, R>(url: string,
                                   payload: any = null,
                                   initialMapper: (data: I) => R,
                                   updateMapper: (update: U, current: R) => R) => {
    const store = streamStateStore()

    const stream = store.openStream<Namespace, Namespace, Namespace>(
        url, payload, initialMapper, updateMapper
    )

    onUnmounted(() => {
        store.closeStream(stream.id)
    })

    return stream
}