import { default as application_45setupu0I3OZJBnQMeta } from "/app/pages/application-setup.vue?macro=true";
import { default as cancelTQ95GeU8UuMeta } from "/app/pages/billing/cancel.vue?macro=true";
import { default as successjigpo33TzcMeta } from "/app/pages/billing/success.vue?macro=true";
import { default as demo_45setupz7JeGSjJIdMeta } from "/app/pages/demo-setup.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index64GoleNCL5Meta } from "/app/pages/root/connections/index.vue?macro=true";
import { default as indexTrkMA31524Meta } from "/app/pages/root/history/index.vue?macro=true";
import { default as indexifdWPXkEnrMeta } from "/app/pages/root/index.vue?macro=true";
import { default as indexKP3uEtQig6Meta } from "/app/pages/root/utilities/index.vue?macro=true";
import { default as indexi6i0PJCLw3Meta } from "/app/pages/root/workspaces/[workspaceId]/index.vue?macro=true";
import { default as indexHQL1T4OK4pMeta } from "/app/pages/root/workspaces/index.vue?macro=true";
import { default as rootThfCCCXF89Meta } from "/app/pages/root.vue?macro=true";
import { default as routesMidTDJNZAKMeta } from "/app/pages/routes.vue?macro=true";
import { default as detaildjGgnQU6KtMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates/detail.vue?macro=true";
import { default as indexJfgJEFUcmVMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates/index.vue?macro=true";
import { default as aggregatesTDW5EQhqooMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates.vue?macro=true";
import { default as _91applicationName_93V5Phokv6iEMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/applications/[applicationName].vue?macro=true";
import { default as indexv0A80YXLAmMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/applications/index.vue?macro=true";
import { default as advantagesjijltWQzJKMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/advantages.vue?macro=true";
import { default as changeRUgKjlrv9FMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/change.vue?macro=true";
import { default as indexqIJVILcqVvMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/index.vue?macro=true";
import { default as debianRYxS8bOzMSMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/debian.vue?macro=true";
import { default as docker1wsvcRD3CyMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/docker.vue?macro=true";
import { default as downloadA41frmq9qOMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/download.vue?macro=true";
import { default as indexCKB71nORFSMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/index.vue?macro=true";
import { default as ubuntuVlNJhGsqfCMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/ubuntu.vue?macro=true";
import { default as installaQrbl8TABfMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install.vue?macro=true";
import { default as flowCWCo0FdOHQMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/flow.vue?macro=true";
import { default as detailuBPb1EYZXCMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers/detail.vue?macro=true";
import { default as indexo6dcGFFj6YMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers/index.vue?macro=true";
import { default as handlersI0elVZlhreMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers.vue?macro=true";
import { default as indexx9btbHgrIxMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/index.vue?macro=true";
import { default as conditions19LgI34tLcMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/conditions.vue?macro=true";
import { default as indexw4T8p6m3pEMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/index.vue?macro=true";
import { default as integrationslHQCB6VvhJMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/integrations.vue?macro=true";
import { default as overridesLal8eM1iZlMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/overrides.vue?macro=true";
import { default as monitoringcw39jao9HPMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring.vue?macro=true";
import { default as dlq4zOCR0kRPvMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/[applicationName]/[processorName]/dlq.vue?macro=true";
import { default as indexIg5NxQDkw1Meta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/[applicationName]/[processorName]/index.vue?macro=true";
import { default as indexVHUR4aO42JMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/index.vue?macro=true";
import { default as activityDwkRHxS7XVMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/activity.vue?macro=true";
import { default as applicationsB3BQqnlGjJMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/applications.vue?macro=true";
import { default as environmentoYfdp0c0ezMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/environment.vue?macro=true";
import { default as handlerssUQr33nzKyMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/handlers.vue?macro=true";
import { default as indexi6Tvl9mea9Meta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/index.vue?macro=true";
import { default as plansDXnVR58LocMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/plans.vue?macro=true";
import { default as subscriptionrkmGL2RvPhMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/subscription.vue?macro=true";
import { default as teamcVS2kmixIsMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/team.vue?macro=true";
import { default as tokensKGqDIY66QCMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/tokens.vue?macro=true";
import { default as workspaceOoGRTibhc1Meta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/workspace.vue?macro=true";
import { default as settingsUfLbKp3yD7Meta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/settings.vue?macro=true";
import { default as startyKyOa2XpcGMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/start.vue?macro=true";
import { default as trial_45activatedbjpEdVvXlMMeta } from "/app/pages/workspace/[workspaceId]/env/[environmentId]/trial-activated.vue?macro=true";
import { default as envKv7NhbMLEsMeta } from "/app/pages/workspace/[workspaceId]/env.vue?macro=true";
import { default as index8LIIAL2QbNMeta } from "/app/pages/workspace/[workspaceId]/index.vue?macro=true";
import { default as overviewXV9683XWzPMeta } from "/app/pages/workspace/[workspaceId]/overview.vue?macro=true";
import { default as activity1WFJMhtxyiMeta } from "/app/pages/workspace/[workspaceId]/settings/activity.vue?macro=true";
import { default as handlersCH4RMo5TcqMeta } from "/app/pages/workspace/[workspaceId]/settings/handlers.vue?macro=true";
import { default as indexnz0dmf2PDdMeta } from "/app/pages/workspace/[workspaceId]/settings/index.vue?macro=true";
import { default as planspmIJCR3x8cMeta } from "/app/pages/workspace/[workspaceId]/settings/plans.vue?macro=true";
import { default as subscriptionTKjdeBdAk3Meta } from "/app/pages/workspace/[workspaceId]/settings/subscription.vue?macro=true";
import { default as teamBoc2foQqT2Meta } from "/app/pages/workspace/[workspaceId]/settings/team.vue?macro=true";
import { default as settingskAymHhjz2uMeta } from "/app/pages/workspace/[workspaceId]/settings.vue?macro=true";
import { default as _91workspaceId_93kYAAeQTP7lMeta } from "/app/pages/workspace/[workspaceId].vue?macro=true";
export default [
  {
    name: application_45setupu0I3OZJBnQMeta?.name ?? "application-setup",
    path: application_45setupu0I3OZJBnQMeta?.path ?? "/application-setup",
    meta: application_45setupu0I3OZJBnQMeta || {},
    alias: application_45setupu0I3OZJBnQMeta?.alias || [],
    redirect: application_45setupu0I3OZJBnQMeta?.redirect,
    component: () => import("/app/pages/application-setup.vue").then(m => m.default || m)
  },
  {
    name: cancelTQ95GeU8UuMeta?.name ?? "billing-cancel",
    path: cancelTQ95GeU8UuMeta?.path ?? "/billing/cancel",
    meta: cancelTQ95GeU8UuMeta || {},
    alias: cancelTQ95GeU8UuMeta?.alias || [],
    redirect: cancelTQ95GeU8UuMeta?.redirect,
    component: () => import("/app/pages/billing/cancel.vue").then(m => m.default || m)
  },
  {
    name: successjigpo33TzcMeta?.name ?? "billing-success",
    path: successjigpo33TzcMeta?.path ?? "/billing/success",
    meta: successjigpo33TzcMeta || {},
    alias: successjigpo33TzcMeta?.alias || [],
    redirect: successjigpo33TzcMeta?.redirect,
    component: () => import("/app/pages/billing/success.vue").then(m => m.default || m)
  },
  {
    name: demo_45setupz7JeGSjJIdMeta?.name ?? "demo-setup",
    path: demo_45setupz7JeGSjJIdMeta?.path ?? "/demo-setup",
    meta: demo_45setupz7JeGSjJIdMeta || {},
    alias: demo_45setupz7JeGSjJIdMeta?.alias || [],
    redirect: demo_45setupz7JeGSjJIdMeta?.redirect,
    component: () => import("/app/pages/demo-setup.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: rootThfCCCXF89Meta?.name ?? undefined,
    path: rootThfCCCXF89Meta?.path ?? "/root",
    meta: rootThfCCCXF89Meta || {},
    alias: rootThfCCCXF89Meta?.alias || [],
    redirect: rootThfCCCXF89Meta?.redirect,
    component: () => import("/app/pages/root.vue").then(m => m.default || m),
    children: [
  {
    name: index64GoleNCL5Meta?.name ?? "root-connections",
    path: index64GoleNCL5Meta?.path ?? "connections",
    meta: index64GoleNCL5Meta || {},
    alias: index64GoleNCL5Meta?.alias || [],
    redirect: index64GoleNCL5Meta?.redirect,
    component: () => import("/app/pages/root/connections/index.vue").then(m => m.default || m)
  },
  {
    name: indexTrkMA31524Meta?.name ?? "root-history",
    path: indexTrkMA31524Meta?.path ?? "history",
    meta: indexTrkMA31524Meta || {},
    alias: indexTrkMA31524Meta?.alias || [],
    redirect: indexTrkMA31524Meta?.redirect,
    component: () => import("/app/pages/root/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexifdWPXkEnrMeta?.name ?? "root",
    path: indexifdWPXkEnrMeta?.path ?? "",
    meta: indexifdWPXkEnrMeta || {},
    alias: indexifdWPXkEnrMeta?.alias || [],
    redirect: indexifdWPXkEnrMeta?.redirect,
    component: () => import("/app/pages/root/index.vue").then(m => m.default || m)
  },
  {
    name: indexKP3uEtQig6Meta?.name ?? "root-utilities",
    path: indexKP3uEtQig6Meta?.path ?? "utilities",
    meta: indexKP3uEtQig6Meta || {},
    alias: indexKP3uEtQig6Meta?.alias || [],
    redirect: indexKP3uEtQig6Meta?.redirect,
    component: () => import("/app/pages/root/utilities/index.vue").then(m => m.default || m)
  },
  {
    name: indexi6i0PJCLw3Meta?.name ?? "root-workspaces-workspaceId",
    path: indexi6i0PJCLw3Meta?.path ?? "workspaces/:workspaceId()",
    meta: indexi6i0PJCLw3Meta || {},
    alias: indexi6i0PJCLw3Meta?.alias || [],
    redirect: indexi6i0PJCLw3Meta?.redirect,
    component: () => import("/app/pages/root/workspaces/[workspaceId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQL1T4OK4pMeta?.name ?? "root-workspaces",
    path: indexHQL1T4OK4pMeta?.path ?? "workspaces",
    meta: indexHQL1T4OK4pMeta || {},
    alias: indexHQL1T4OK4pMeta?.alias || [],
    redirect: indexHQL1T4OK4pMeta?.redirect,
    component: () => import("/app/pages/root/workspaces/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: routesMidTDJNZAKMeta?.name ?? "routes",
    path: routesMidTDJNZAKMeta?.path ?? "/routes",
    meta: routesMidTDJNZAKMeta || {},
    alias: routesMidTDJNZAKMeta?.alias || [],
    redirect: routesMidTDJNZAKMeta?.redirect,
    component: () => import("/app/pages/routes.vue").then(m => m.default || m)
  },
  {
    name: _91workspaceId_93kYAAeQTP7lMeta?.name ?? undefined,
    path: _91workspaceId_93kYAAeQTP7lMeta?.path ?? "/workspace/:workspaceId()",
    meta: _91workspaceId_93kYAAeQTP7lMeta || {},
    alias: _91workspaceId_93kYAAeQTP7lMeta?.alias || [],
    redirect: _91workspaceId_93kYAAeQTP7lMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId].vue").then(m => m.default || m),
    children: [
  {
    name: envKv7NhbMLEsMeta?.name ?? "workspace-workspaceId-env",
    path: envKv7NhbMLEsMeta?.path ?? "env",
    meta: envKv7NhbMLEsMeta || {},
    alias: envKv7NhbMLEsMeta?.alias || [],
    redirect: envKv7NhbMLEsMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env.vue").then(m => m.default || m),
    children: [
  {
    name: aggregatesTDW5EQhqooMeta?.name ?? undefined,
    path: aggregatesTDW5EQhqooMeta?.path ?? ":environmentId()/aggregates",
    meta: aggregatesTDW5EQhqooMeta || {},
    alias: aggregatesTDW5EQhqooMeta?.alias || [],
    redirect: aggregatesTDW5EQhqooMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates.vue").then(m => m.default || m),
    children: [
  {
    name: detaildjGgnQU6KtMeta?.name ?? "workspace-workspaceId-env-environmentId-aggregates-detail",
    path: detaildjGgnQU6KtMeta?.path ?? "detail",
    meta: detaildjGgnQU6KtMeta || {},
    alias: detaildjGgnQU6KtMeta?.alias || [],
    redirect: detaildjGgnQU6KtMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates/detail.vue").then(m => m.default || m)
  },
  {
    name: indexJfgJEFUcmVMeta?.name ?? "workspace-workspaceId-env-environmentId-aggregates",
    path: indexJfgJEFUcmVMeta?.path ?? "",
    meta: indexJfgJEFUcmVMeta || {},
    alias: indexJfgJEFUcmVMeta?.alias || [],
    redirect: indexJfgJEFUcmVMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/aggregates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91applicationName_93V5Phokv6iEMeta?.name ?? "workspace-workspaceId-env-environmentId-applications-applicationName",
    path: _91applicationName_93V5Phokv6iEMeta?.path ?? ":environmentId()/applications/:applicationName()",
    meta: _91applicationName_93V5Phokv6iEMeta || {},
    alias: _91applicationName_93V5Phokv6iEMeta?.alias || [],
    redirect: _91applicationName_93V5Phokv6iEMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/applications/[applicationName].vue").then(m => m.default || m)
  },
  {
    name: indexv0A80YXLAmMeta?.name ?? "workspace-workspaceId-env-environmentId-applications",
    path: indexv0A80YXLAmMeta?.path ?? ":environmentId()/applications",
    meta: indexv0A80YXLAmMeta || {},
    alias: indexv0A80YXLAmMeta?.alias || [],
    redirect: indexv0A80YXLAmMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/applications/index.vue").then(m => m.default || m)
  },
  {
    name: advantagesjijltWQzJKMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-advantages",
    path: advantagesjijltWQzJKMeta?.path ?? ":environmentId()/cluster/advantages",
    meta: advantagesjijltWQzJKMeta || {},
    alias: advantagesjijltWQzJKMeta?.alias || [],
    redirect: advantagesjijltWQzJKMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/advantages.vue").then(m => m.default || m)
  },
  {
    name: changeRUgKjlrv9FMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-change",
    path: changeRUgKjlrv9FMeta?.path ?? ":environmentId()/cluster/change",
    meta: changeRUgKjlrv9FMeta || {},
    alias: changeRUgKjlrv9FMeta?.alias || [],
    redirect: changeRUgKjlrv9FMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/change.vue").then(m => m.default || m)
  },
  {
    name: indexqIJVILcqVvMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster",
    path: indexqIJVILcqVvMeta?.path ?? ":environmentId()/cluster",
    meta: indexqIJVILcqVvMeta || {},
    alias: indexqIJVILcqVvMeta?.alias || [],
    redirect: indexqIJVILcqVvMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/index.vue").then(m => m.default || m)
  },
  {
    name: installaQrbl8TABfMeta?.name ?? undefined,
    path: installaQrbl8TABfMeta?.path ?? ":environmentId()/cluster/install",
    meta: installaQrbl8TABfMeta || {},
    alias: installaQrbl8TABfMeta?.alias || [],
    redirect: installaQrbl8TABfMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install.vue").then(m => m.default || m),
    children: [
  {
    name: debianRYxS8bOzMSMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-install-debian",
    path: debianRYxS8bOzMSMeta?.path ?? "debian",
    meta: debianRYxS8bOzMSMeta || {},
    alias: debianRYxS8bOzMSMeta?.alias || [],
    redirect: debianRYxS8bOzMSMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/debian.vue").then(m => m.default || m)
  },
  {
    name: docker1wsvcRD3CyMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-install-docker",
    path: docker1wsvcRD3CyMeta?.path ?? "docker",
    meta: docker1wsvcRD3CyMeta || {},
    alias: docker1wsvcRD3CyMeta?.alias || [],
    redirect: docker1wsvcRD3CyMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/docker.vue").then(m => m.default || m)
  },
  {
    name: downloadA41frmq9qOMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-install-download",
    path: downloadA41frmq9qOMeta?.path ?? "download",
    meta: downloadA41frmq9qOMeta || {},
    alias: downloadA41frmq9qOMeta?.alias || [],
    redirect: downloadA41frmq9qOMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/download.vue").then(m => m.default || m)
  },
  {
    name: indexCKB71nORFSMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-install",
    path: indexCKB71nORFSMeta?.path ?? "",
    meta: indexCKB71nORFSMeta || {},
    alias: indexCKB71nORFSMeta?.alias || [],
    redirect: indexCKB71nORFSMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/index.vue").then(m => m.default || m)
  },
  {
    name: ubuntuVlNJhGsqfCMeta?.name ?? "workspace-workspaceId-env-environmentId-cluster-install-ubuntu",
    path: ubuntuVlNJhGsqfCMeta?.path ?? "ubuntu",
    meta: ubuntuVlNJhGsqfCMeta || {},
    alias: ubuntuVlNJhGsqfCMeta?.alias || [],
    redirect: ubuntuVlNJhGsqfCMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/cluster/install/ubuntu.vue").then(m => m.default || m)
  }
]
  },
  {
    name: flowCWCo0FdOHQMeta?.name ?? "workspace-workspaceId-env-environmentId-flow",
    path: flowCWCo0FdOHQMeta?.path ?? ":environmentId()/flow",
    meta: flowCWCo0FdOHQMeta || {},
    alias: flowCWCo0FdOHQMeta?.alias || [],
    redirect: flowCWCo0FdOHQMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/flow.vue").then(m => m.default || m)
  },
  {
    name: handlersI0elVZlhreMeta?.name ?? undefined,
    path: handlersI0elVZlhreMeta?.path ?? ":environmentId()/handlers",
    meta: handlersI0elVZlhreMeta || {},
    alias: handlersI0elVZlhreMeta?.alias || [],
    redirect: handlersI0elVZlhreMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers.vue").then(m => m.default || m),
    children: [
  {
    name: detailuBPb1EYZXCMeta?.name ?? "workspace-workspaceId-env-environmentId-handlers-detail",
    path: detailuBPb1EYZXCMeta?.path ?? "detail",
    meta: detailuBPb1EYZXCMeta || {},
    alias: detailuBPb1EYZXCMeta?.alias || [],
    redirect: detailuBPb1EYZXCMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers/detail.vue").then(m => m.default || m)
  },
  {
    name: indexo6dcGFFj6YMeta?.name ?? "workspace-workspaceId-env-environmentId-handlers",
    path: indexo6dcGFFj6YMeta?.path ?? "",
    meta: indexo6dcGFFj6YMeta || {},
    alias: indexo6dcGFFj6YMeta?.alias || [],
    redirect: indexo6dcGFFj6YMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/handlers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexx9btbHgrIxMeta?.name ?? "workspace-workspaceId-env-environmentId",
    path: indexx9btbHgrIxMeta?.path ?? ":environmentId()",
    meta: indexx9btbHgrIxMeta || {},
    alias: indexx9btbHgrIxMeta?.alias || [],
    redirect: indexx9btbHgrIxMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/index.vue").then(m => m.default || m)
  },
  {
    name: monitoringcw39jao9HPMeta?.name ?? undefined,
    path: monitoringcw39jao9HPMeta?.path ?? ":environmentId()/monitoring",
    meta: monitoringcw39jao9HPMeta || {},
    alias: monitoringcw39jao9HPMeta?.alias || [],
    redirect: monitoringcw39jao9HPMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring.vue").then(m => m.default || m),
    children: [
  {
    name: conditions19LgI34tLcMeta?.name ?? "workspace-workspaceId-env-environmentId-monitoring-conditions",
    path: conditions19LgI34tLcMeta?.path ?? "conditions",
    meta: conditions19LgI34tLcMeta || {},
    alias: conditions19LgI34tLcMeta?.alias || [],
    redirect: conditions19LgI34tLcMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/conditions.vue").then(m => m.default || m)
  },
  {
    name: indexw4T8p6m3pEMeta?.name ?? "workspace-workspaceId-env-environmentId-monitoring",
    path: indexw4T8p6m3pEMeta?.path ?? "",
    meta: indexw4T8p6m3pEMeta || {},
    alias: indexw4T8p6m3pEMeta?.alias || [],
    redirect: indexw4T8p6m3pEMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/index.vue").then(m => m.default || m)
  },
  {
    name: integrationslHQCB6VvhJMeta?.name ?? "workspace-workspaceId-env-environmentId-monitoring-integrations",
    path: integrationslHQCB6VvhJMeta?.path ?? "integrations",
    meta: integrationslHQCB6VvhJMeta || {},
    alias: integrationslHQCB6VvhJMeta?.alias || [],
    redirect: integrationslHQCB6VvhJMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/integrations.vue").then(m => m.default || m)
  },
  {
    name: overridesLal8eM1iZlMeta?.name ?? "workspace-workspaceId-env-environmentId-monitoring-overrides",
    path: overridesLal8eM1iZlMeta?.path ?? "overrides",
    meta: overridesLal8eM1iZlMeta || {},
    alias: overridesLal8eM1iZlMeta?.alias || [],
    redirect: overridesLal8eM1iZlMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/monitoring/overrides.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dlq4zOCR0kRPvMeta?.name ?? "workspace-workspaceId-env-environmentId-processor-applicationName-processorName-dlq",
    path: dlq4zOCR0kRPvMeta?.path ?? ":environmentId()/processor/:applicationName()/:processorName()/dlq",
    meta: dlq4zOCR0kRPvMeta || {},
    alias: dlq4zOCR0kRPvMeta?.alias || [],
    redirect: dlq4zOCR0kRPvMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/[applicationName]/[processorName]/dlq.vue").then(m => m.default || m)
  },
  {
    name: indexIg5NxQDkw1Meta?.name ?? "workspace-workspaceId-env-environmentId-processor-applicationName-processorName",
    path: indexIg5NxQDkw1Meta?.path ?? ":environmentId()/processor/:applicationName()/:processorName()",
    meta: indexIg5NxQDkw1Meta || {},
    alias: indexIg5NxQDkw1Meta?.alias || [],
    redirect: indexIg5NxQDkw1Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/[applicationName]/[processorName]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVHUR4aO42JMeta?.name ?? "workspace-workspaceId-env-environmentId-processor",
    path: indexVHUR4aO42JMeta?.path ?? ":environmentId()/processor",
    meta: indexVHUR4aO42JMeta || {},
    alias: indexVHUR4aO42JMeta?.alias || [],
    redirect: indexVHUR4aO42JMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/processor/index.vue").then(m => m.default || m)
  },
  {
    name: settingsUfLbKp3yD7Meta?.name ?? undefined,
    path: settingsUfLbKp3yD7Meta?.path ?? ":environmentId()/settings",
    meta: settingsUfLbKp3yD7Meta || {},
    alias: settingsUfLbKp3yD7Meta?.alias || [],
    redirect: settingsUfLbKp3yD7Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: activityDwkRHxS7XVMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-activity",
    path: activityDwkRHxS7XVMeta?.path ?? "activity",
    meta: activityDwkRHxS7XVMeta || {},
    alias: activityDwkRHxS7XVMeta?.alias || [],
    redirect: activityDwkRHxS7XVMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/activity.vue").then(m => m.default || m)
  },
  {
    name: applicationsB3BQqnlGjJMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-applications",
    path: applicationsB3BQqnlGjJMeta?.path ?? "applications",
    meta: applicationsB3BQqnlGjJMeta || {},
    alias: applicationsB3BQqnlGjJMeta?.alias || [],
    redirect: applicationsB3BQqnlGjJMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/applications.vue").then(m => m.default || m)
  },
  {
    name: environmentoYfdp0c0ezMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-environment",
    path: environmentoYfdp0c0ezMeta?.path ?? "environment",
    meta: environmentoYfdp0c0ezMeta || {},
    alias: environmentoYfdp0c0ezMeta?.alias || [],
    redirect: environmentoYfdp0c0ezMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/environment.vue").then(m => m.default || m)
  },
  {
    name: handlerssUQr33nzKyMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-handlers",
    path: handlerssUQr33nzKyMeta?.path ?? "handlers",
    meta: handlerssUQr33nzKyMeta || {},
    alias: handlerssUQr33nzKyMeta?.alias || [],
    redirect: handlerssUQr33nzKyMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/handlers.vue").then(m => m.default || m)
  },
  {
    name: indexi6Tvl9mea9Meta?.name ?? "workspace-workspaceId-env-environmentId-settings",
    path: indexi6Tvl9mea9Meta?.path ?? "",
    meta: indexi6Tvl9mea9Meta || {},
    alias: indexi6Tvl9mea9Meta?.alias || [],
    redirect: indexi6Tvl9mea9Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: plansDXnVR58LocMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-plans",
    path: plansDXnVR58LocMeta?.path ?? "plans",
    meta: plansDXnVR58LocMeta || {},
    alias: plansDXnVR58LocMeta?.alias || [],
    redirect: plansDXnVR58LocMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/plans.vue").then(m => m.default || m)
  },
  {
    name: subscriptionrkmGL2RvPhMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-subscription",
    path: subscriptionrkmGL2RvPhMeta?.path ?? "subscription",
    meta: subscriptionrkmGL2RvPhMeta || {},
    alias: subscriptionrkmGL2RvPhMeta?.alias || [],
    redirect: subscriptionrkmGL2RvPhMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/subscription.vue").then(m => m.default || m)
  },
  {
    name: teamcVS2kmixIsMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-team",
    path: teamcVS2kmixIsMeta?.path ?? "team",
    meta: teamcVS2kmixIsMeta || {},
    alias: teamcVS2kmixIsMeta?.alias || [],
    redirect: teamcVS2kmixIsMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/team.vue").then(m => m.default || m)
  },
  {
    name: tokensKGqDIY66QCMeta?.name ?? "workspace-workspaceId-env-environmentId-settings-tokens",
    path: tokensKGqDIY66QCMeta?.path ?? "tokens",
    meta: tokensKGqDIY66QCMeta || {},
    alias: tokensKGqDIY66QCMeta?.alias || [],
    redirect: tokensKGqDIY66QCMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/tokens.vue").then(m => m.default || m)
  },
  {
    name: workspaceOoGRTibhc1Meta?.name ?? "workspace-workspaceId-env-environmentId-settings-workspace",
    path: workspaceOoGRTibhc1Meta?.path ?? "workspace",
    meta: workspaceOoGRTibhc1Meta || {},
    alias: workspaceOoGRTibhc1Meta?.alias || [],
    redirect: workspaceOoGRTibhc1Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: startyKyOa2XpcGMeta?.name ?? "workspace-workspaceId-env-environmentId-start",
    path: startyKyOa2XpcGMeta?.path ?? ":environmentId()/start",
    meta: startyKyOa2XpcGMeta || {},
    alias: startyKyOa2XpcGMeta?.alias || [],
    redirect: startyKyOa2XpcGMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/start.vue").then(m => m.default || m)
  },
  {
    name: trial_45activatedbjpEdVvXlMMeta?.name ?? "workspace-workspaceId-env-environmentId-trial-activated",
    path: trial_45activatedbjpEdVvXlMMeta?.path ?? ":environmentId()/trial-activated",
    meta: trial_45activatedbjpEdVvXlMMeta || {},
    alias: trial_45activatedbjpEdVvXlMMeta?.alias || [],
    redirect: trial_45activatedbjpEdVvXlMMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/env/[environmentId]/trial-activated.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8LIIAL2QbNMeta?.name ?? "workspace-workspaceId",
    path: index8LIIAL2QbNMeta?.path ?? "",
    meta: index8LIIAL2QbNMeta || {},
    alias: index8LIIAL2QbNMeta?.alias || [],
    redirect: index8LIIAL2QbNMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewXV9683XWzPMeta?.name ?? "workspace-workspaceId-overview",
    path: overviewXV9683XWzPMeta?.path ?? "overview",
    meta: overviewXV9683XWzPMeta || {},
    alias: overviewXV9683XWzPMeta?.alias || [],
    redirect: overviewXV9683XWzPMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/overview.vue").then(m => m.default || m)
  },
  {
    name: settingskAymHhjz2uMeta?.name ?? undefined,
    path: settingskAymHhjz2uMeta?.path ?? "settings",
    meta: settingskAymHhjz2uMeta || {},
    alias: settingskAymHhjz2uMeta?.alias || [],
    redirect: settingskAymHhjz2uMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: activity1WFJMhtxyiMeta?.name ?? "workspace-workspaceId-settings-activity",
    path: activity1WFJMhtxyiMeta?.path ?? "activity",
    meta: activity1WFJMhtxyiMeta || {},
    alias: activity1WFJMhtxyiMeta?.alias || [],
    redirect: activity1WFJMhtxyiMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/activity.vue").then(m => m.default || m)
  },
  {
    name: handlersCH4RMo5TcqMeta?.name ?? "workspace-workspaceId-settings-handlers",
    path: handlersCH4RMo5TcqMeta?.path ?? "handlers",
    meta: handlersCH4RMo5TcqMeta || {},
    alias: handlersCH4RMo5TcqMeta?.alias || [],
    redirect: handlersCH4RMo5TcqMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/handlers.vue").then(m => m.default || m)
  },
  {
    name: indexnz0dmf2PDdMeta?.name ?? "workspace-workspaceId-settings",
    path: indexnz0dmf2PDdMeta?.path ?? "",
    meta: indexnz0dmf2PDdMeta || {},
    alias: indexnz0dmf2PDdMeta?.alias || [],
    redirect: indexnz0dmf2PDdMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: planspmIJCR3x8cMeta?.name ?? "workspace-workspaceId-settings-plans",
    path: planspmIJCR3x8cMeta?.path ?? "plans",
    meta: planspmIJCR3x8cMeta || {},
    alias: planspmIJCR3x8cMeta?.alias || [],
    redirect: planspmIJCR3x8cMeta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/plans.vue").then(m => m.default || m)
  },
  {
    name: subscriptionTKjdeBdAk3Meta?.name ?? "workspace-workspaceId-settings-subscription",
    path: subscriptionTKjdeBdAk3Meta?.path ?? "subscription",
    meta: subscriptionTKjdeBdAk3Meta || {},
    alias: subscriptionTKjdeBdAk3Meta?.alias || [],
    redirect: subscriptionTKjdeBdAk3Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/subscription.vue").then(m => m.default || m)
  },
  {
    name: teamBoc2foQqT2Meta?.name ?? "workspace-workspaceId-settings-team",
    path: teamBoc2foQqT2Meta?.path ?? "team",
    meta: teamBoc2foQqT2Meta || {},
    alias: teamBoc2foQqT2Meta?.alias || [],
    redirect: teamBoc2foQqT2Meta?.redirect,
    component: () => import("/app/pages/workspace/[workspaceId]/settings/team.vue").then(m => m.default || m)
  }
]
  }
]
  }
]