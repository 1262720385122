import * as Sentry from "@sentry/vue";
import {browserTracingIntegration, replayIntegration} from "@sentry/vue";

const config = getConfig()

export default defineNuxtPlugin((nuxtApp) => {
    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: "https://80b4f33651e7054e9987ffbd93b21640@o1158005.ingest.sentry.io/4506598884704256",
        enabled: config.sentryEnabled,
        integrations: [
            browserTracingIntegration({
            }),
            replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
})