<script setup lang="ts">
import {useAuthentication} from "~/stores/authentication";

const {logout} = useAuthentication()
</script>

<template>
  <v-btn @click.prevent="logout" variant="text" color="primary" style="width: 100%">
    <PowerIcon stroke-width="1.5" class="mr-1" size="22" />Logout
  </v-btn>
</template>
