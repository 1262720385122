<script setup>
import {computed} from "vue";
import {useCustomizerStore} from "@/stores/customizer";

const customizer = useCustomizerStore();

const dark = computed(() => {
  return customizer.actTheme === "DARK_THEME";
});
</script>
<template>
  <slot name="dark" v-if="dark" />
  <slot name="light" v-else />
</template>
