<script setup lang="ts">
import {useAuthentication} from "~/stores/authentication";
import {storeToRefs} from "pinia";

const {authenticated} = storeToRefs(useAuthentication())

</script>

<template>
  <v-app-bar elevation="10" height="70">
    <div :class="'v-toolbar__content maxWidth'">
      <div class="mx-3" style="height: 40px; width: 250px">
        <LogoConsole/>
      </div>

      <v-spacer/>

      <v-btn variant="text" rounded href="https://academy.axoniq.io" target="_blank" :active="false">
        <LogoAcademy :size="24"/>
        <span class="ml-2">Academy</span>

        <v-menu activator="parent" open-on-hover>
          <v-card style="max-width: 400px">
            <v-card-text>
              <p>
                The AxonIQ Academy is your go-to place for learning about Axon Framework and Axon Server.
                It provides you with the knowledge you need to know about Message-Driven Architecture and how to implement it.
              </p>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-btn>

      <v-btn variant="text" rounded href="https://discuss.axoniq.io" target="_blank" :active="false">
        <LogoDiscuss :size="24"/>
        <span class="ml-2">Discuss</span>

        <v-menu activator="parent" open-on-hover>
          <v-card style="max-width: 400px">
            <v-card-text>
              <p>
                AxonIQ has a vibrant community of Axon Framework and Axon Server users. <br/>Join the discussion on our
                forum, where you can ask questions, share your experiences, and learn from others.
              </p>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-btn>

      <NavbarProfile class="ml-6 me-4" v-if="authenticated"/>
    </div>
  </v-app-bar>
</template>
