import {storeToRefs} from "pinia";
import {computed, onUnmounted, Ref} from "vue";
import type {HandlerSpecification} from "~/types/spaces.types";
import {defineMultiPollingStore} from "~/stores/_archetypes/polling";


export const useHandlerDetail = (workspaceId: string): {
    summary: Ref<HandlerSpecification | null>;
    loading: Ref<boolean>;
    error: Ref<boolean>
} => {
    const store = handlerDetailStore()

    const id = store.registerPolling({workspaceId})
    onUnmounted(() => {
        store.deregisterPolling(id)
    })

    const storeRefs = storeToRefs(store)
    const summary = computed((() => {
        return storeRefs.state.value[id]?.item as HandlerSpecification | null
    }))
    const loading = computed((() => {
        return storeRefs.state.value[id]?.loading
    }))
    const error = computed((() => {
        return storeRefs.state.value[id]?.error
    }))

    return {loading, summary, error}
}


export const handlerDetailStore = defineMultiPollingStore<
    { workspaceId: string },
    HandlerSpecification
>(
    'handlerDetail',
    'workspace/handler/detail',
    60000,
)

