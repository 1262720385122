import {defineStore} from "pinia";
import {useConnectionStore} from "~/stores/connection";

interface MultiPollingStoreItemState<T> {
    payload: any,
    references: number,
    loading: boolean,
    lastDate: Date,
    errorCount: number,
    error: boolean,
    stopHandler: () => void,
    item: T | null,
    fetchFunction: () => Promise<void>
}

export const defineMultiPollingStore = <R, T>(
    name: string,
    url: string,
    interval: number,
    dataMapper: (data: any) => T = (data) => data,
) => defineStore(name, {
    state: () => ({
        state: {} as { [request: string]: MultiPollingStoreItemState<T> }
    }),

    actions: {
        registerPolling(payload: R) {
            const openPollId = Object.keys(this.state)
                .find(id => {
                    return JSON.stringify(this.state[id].payload) === JSON.stringify(payload);
                })
            if (this.state[openPollId]) {
                this.state[openPollId].references++
                return openPollId
            }

            const id = generateId()
            // New polling!
            console.log(`[POLL][${id}] Starting polling of ${url} with payload ${JSON.stringify(payload)}`)
            const {query} = useConnectionStore()
            const fetchFunction = async () => {
                query<T>(url, payload, true).then((r) => {
                    itemState.item = dataMapper(r)
                    itemState.loading = false
                    itemState.error = false
                    itemState.lastDate = new Date()
                    itemState.errorCount = 0
                }).catch(() => {
                    itemState.errorCount += 1
                    if(itemState.errorCount > 5) {
                        itemState.loading = false
                        itemState.error = true
                    }
                })
            }
            const intervalValue = setInterval(fetchFunction, interval as any)
            // Queue initial fetch
            setTimeout(fetchFunction)
            this.state[id] = {
                payload: payload,
                references: 1,
                loading: true,
                errorCount: 0,
                error: false,
                stopHandler: () => {
                    clearInterval(intervalValue)
                    console.log(`[POLL][${id}] Closing polling of ${url} with payload ${payload}`)
                },
                item: null,
                lastDate: new Date(),
                fetchFunction: fetchFunction
            }
            const itemState = this.state[id]
            return id
        },
        forcePoll(id: string): Promise<void> {
            console.log(`[POLL][${id}] Forcing polling of ${url} with payload ${JSON.stringify(this.state[id].payload)}`)
            return this.state[id].fetchFunction()
        },
        deregisterPolling(id: string) {
            // Wait 1000ms to check whether to close it. If we do this too aggressively, we might close it while
            // another component opens the polling 20ms later. Such a shame that would be.
            setTimeout(() => {
                if (this.state[id]) {
                    this.state[id].references--
                    if (this.state[id].references == 0) {
                        this.state[id].stopHandler()
                        delete this.state[id]
                    }
                }
            }, 1000)
        }
    }

})