<script setup lang="ts">
import {useCustomizerStore} from "~/stores/customizer";

const customizer = useCustomizerStore();
</script>

<template>
  <v-app id="console-app" :theme="customizer.actTheme" :class="[
        customizer.actTheme,
        'horizontalLayout',
    ]">
    <div :class="'maxWidth'">
      <Navbar/>
      <v-main>
        <v-container>
          <InfoScreenContainer title="404 Not Found">
            <p>We could not find the page you were looking for!</p>
          </InfoScreenContainer>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>
