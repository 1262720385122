<script setup lang="ts">

import {useSpacesStore} from "~/stores/spaces";
import {storeToRefs} from "pinia";
import CodeBlock from "vue3-code-block";
import {useCodeTheme} from "~/utils/codeTheme";
import {streamStateStore} from "~/stores/_archetypes/rsocket";
import {useConnectionStore} from "~/stores/connection";
import {useUiNotifications} from "~/stores/ui-notifications";
import {useApplicationState} from "~/stores/application-state";
import {useAuthentication} from "~/stores/authentication";
import {useHandlerStore} from "~/stores/statistics.handlers";
import {useAggregateStore} from "~/stores/statistics.aggregates";
import {handlerSummaryStore} from "~/stores/handlerSummary";
import {handlerDetailStore} from "~/stores/handlerDetail";

const {codeTheme} = useCodeTheme()
const stores = {
  applicationState: storeToRefs(useApplicationState()),
  workspace: storeToRefs(useSpacesStore()),
  streaming: storeToRefs(streamStateStore()),
  connection: storeToRefs(useConnectionStore()),
  notifications: storeToRefs(useUiNotifications()),
  handlers: storeToRefs(useHandlerStore()),
  aggregates: storeToRefs(useAggregateStore()),
  handlerSummary: storeToRefs(handlerSummaryStore()),
  handlerDetail: storeToRefs(handlerDetailStore()),
  authentication: storeToRefs(useAuthentication()),
}

const selectOptions = Object.keys(stores).flatMap(store => {
  return Object.keys(stores[store]).map(ref => {
    return {
      key: store + ref,
      store,
      ref
    }
  })
})
const selectedItem = ref(selectOptions[0].key)
const state = ref<any>(null)

watch(selectedItem, () => {
  const selectedRef = selectOptions.find(option => option.key === selectedItem.value)
  let item = stores[selectedRef.store][selectedRef.ref];
  if (isRef(item)) {
    item = item.value
  }
  if (!item) {
    item = `${item}` as any
  }
  state.value = item
}, {immediate: true})

/**
 * Standlone component to debug store contents. Can be opened at any point with CTRL+ALT+SHIFT+D
 */
const isOpen = ref(false)
const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'D' && e.ctrlKey && e.shiftKey && e.altKey) {
    isOpen.value = !isOpen.value
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeyDown)
})
onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown)
})

</script>

<template>
  <v-dialog :model-value="isOpen" height="90vh">
    <v-card v-if="isOpen">
      <v-card-title class="pa-4 bg-lightsecondary">
        <div class="d-flex justify-space-between align-center">
          <div class="ml-2">Store debug</div>
        </div>
      </v-card-title>
      <v-card-text class="pb-4">
        <v-row>
          <v-col style="max-height: 90vh; overflow-y: scroll">
            <div v-for="store in Object.keys(stores)">
              <h2 class="my-4">{{ store }}</h2>

              <v-tabs direction="vertical" v-model="selectedItem">
                <v-tab v-for="key in Object.keys(stores[store])" :key="store + key" :value="store + key">
                  {{ key }}
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
          <v-col style="max-height: 90vh; overflow-y: scroll">
            <CodeBlock v-if="state" :code="JSON.stringify(state, null, 4)" :highlightjs="true" :theme="codeTheme"
                       lang="json" persistentCopyButton/>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
