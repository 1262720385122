import type {ThemeTypes} from '@/types/themeTypes/ThemeType';

const LIGHT_THEME: ThemeTypes = {
    name: 'LIGHT_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#1374CF', //peacock
        secondary: '#FE5E00', //fox
        info: '#00B7BF',
        success: '#47BF4A',
        accent: '#FFAB91',
        warning: '#FE5E00',
        error: '#D33333',
        lightprimary: '#9ECFFC', //pale peacock 
        lightsecondary: '#FBCAAD',//pale fox
        lightsuccess: '#CFCCFE',
        lighterror: '#f7cece',
        lightwarning: '#F7EFBA',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#26262a',
        containerBg: '#ffffff',
        background: '#ECEDEE',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};


export { LIGHT_THEME };
